<template>
  <div style="margin: 10px">
    <b-row cols="1" cols-md="2">
      <!-- Kiosztható kérdőívek -->
      <div style="padding: 10px">
        <b-row style="margin: 0px 0px 30px">
          <fp-input
            style="margin: 0px 5px"
            :label="$t('base.basic.filter')"
            @change="filterQuestionnaireList"
            v-model="filter"
            class="eh-fix-filter-input-width"
          />
          <fp-date-range-button
            :label="
              $t('healthProfessional.questionnaires.assignableTab.createDate')
            "
            v-model="createdDateFilter"
            @change="filterQuestionnaireList"
          />
        </b-row>
        <fp-table-list
          style="margin-top: -20px"
          refId="questionnaire-list-for-patients"
          id="questionnaire-list-for-patients"
          class="questionnaires-to-patient-table"
          :hasPagination="true"
          :items="filteredQuestionnaireList"
          :fields="questionnaireListFields"
          @row-selected="questionnaireSelected"
          selectMode="single"
          :selectable="true"
        >
          <template #Title="row">
            {{ $getLanguagedText(row.item.Title, row.item.Languages) }}
          </template>
          <template #Description="row">
            {{ $getLanguagedText(row.item.Description, row.item.Languages) }}
          </template>
          <template #Added="row">
            {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
          </template>
          <template #Languages="row">
            <span v-for="lang in row.item.Languages" :key="lang">
              <img
                width="25px"
                style="margin: 2px 5px"
                :src="require('@/assets' + $enums.Languages[lang].flag)"
              />
            </span>
          </template>
          <template #Buttons="row">
            <b-icon
              class="h4"
              v-b-tooltip.hover="$t('base.basic.view')"
              style="cursor: pointer"
              icon="eye-fill"
              @click="$set(row.item, 'ShowFormViewer', true)"
            />
            <fp-form-modal size="xl" v-model="row.item.ShowFormViewer">
              <template #content>
                <fp-form-schema-builder
                  :formSchemaId="row.item.FormSchemaId"
                  :disabled="true"
                />
              </template>
            </fp-form-modal>
          </template>
        </fp-table-list>
      </div>
      <!-- Betegek listája -->
      <div style="padding: 10px">
        <div>
          <fp-input
            class="eh-fix-filter-input-width"
            style="margin-top: 0px"
            :label="$t('base.basic.filter')"
            @change="filterParticipantList"
          />
        </div>
        <!-- <GroupRowMultiselectTable
          style="
            margin-top: 10px;
            border: solid 3px #627183;
            border-radius: 10px;
            max-height: 500px;
            overflow: auto;
          "
          :emptyMessage="
            $t('healthProfessional.questionnaires.assignableTab.noParticipant')
          "
          @selectedItems="selectedParticipantList = $event"
          :items="patienListGroupedByTags"
          :fields="participantListFields"
        /> -->
        <fp-table-list
          :items="participantList"
          :fields="participantListFields"
          :selectable="true"
          @row-selected="selectParticipants"
        >
          <!-- TODO: slot cellaformázás -->
        </fp-table-list>
      </div>
    </b-row>
    <b-check v-model="asTask">{{
      $t("healthProfessional.questionnaires.assignableTab.newFormByTask")
    }}</b-check>
    <b-check v-if="asTask" v-model="hardDeadline">
      {{
        $t(
          "healthProfessional.questionnaires.assignableTab.newFormHardDeadline"
        )
      }}
    </b-check>
    <b-row
      style="margin: 0px 0px 20px"
      align-h="between"
      align-v="end"
      cols="1"
      cols-md="2"
    >
      <div style="display: flex">
        <div v-if="asTask" style="width: 400px">
          <fp-date-time-picker
            :label="
              $t('healthProfessional.questionnaires.assignableTab.newSendDate')
            "
            v-model="startDate"
            :required="true"
          />
        </div>
        <div v-if="asTask" style="width: 300px; padding-left: 20px">
          <fp-input
            :label="
              $t('healthProfessional.questionnaires.assignableTab.newdeadline')
            "
            :placeholder="
              $t(
                'healthProfessional.questionnaires.assignableTab.newdeadlineUnit'
              )
            "
            :description="
              $t('healthProfessional.questionnaires.assignableTab.fillTime')
            "
            v-model="deadlineDate"
            :required="true"
          />
        </div>
      </div>
      <div style="max-width: 300px; padding: 10px">
        <b-button
          style="height: fit-content"
          :disabled="
            selectedQuestionnaire.length == 0 ||
            selectedParticipantList.length == 0
          "
          @click="renderQuestionnaireToParticipant"
          >{{
            this.$t(
              "healthProfessional.questionnaires.assignableTab.giveQuestionnaries",
              {
                count: selectedParticipantList.length,
              }
            )
          }}</b-button
        >
      </div>
    </b-row>
  </div>
</template>
<script>
import moment from "moment";
import { ProjectLogic } from "../../../Logic/Backend/project.js";
import { FormLogic } from "../../../Logic/Backend/form";
//import GroupRowMultiselectTable from "@/components/table/GroupRowMultiselectTable";

export default {
  name: "QuestionnaireAssignableList",
  /* components: {
    GroupRowMultiselectTable,
  }, */
  data() {
    return {
      patienListGroupedByTags: [],
      participantList: [],
      questionnaireList: [],
      questionnaireListFields: [
        {
          key: "Title",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.formTitle"
          ),
        },
        {
          key: "Description",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.formDescription"
          ),
        },
        {
          key: "Languages",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.supportedLanguages"
          ),
        },
        {
          key: "Added",
          label: this.$t(
            "healthProfessional.questionnaires.assignableTab.formCreatedDate"
          ),
        },
        { key: "Buttons", label: "" },
      ],
      selectedParticipantList: [],
      selectedQuestionnaire: [],
      filteredQuestionnaireList: [],
      filteredParticipantList: [],
      deadlineDate: null,
      startDate: moment().format(),
      filter: null,
      createdDateFilter: null,
      asTask: false,
      hardDeadline: false,
    };
  },
  props: {
    projectItems: Array,
    managerType: String,
    participantListFields: Array,
  },
  methods: {
    selectParticipants(row) {
      this.selectedParticipantList = row;
    },
    filterQuestionnaireList() {
      this.filteredQuestionnaireList = this.questionnaireList;
      if (this.filter) {
        this.filteredQuestionnaireList = this.$filterQuestionnaireList(
          this.filter,
          this.questionnaireList,
          ["Title", "Description"]
        );
      }
      if (this.createdDateFilter) {
        this.filteredQuestionnaireList = this.filteredQuestionnaireList.filter(
          (task) => {
            if (this.createdDateFilter.from && this.createdDateFilter.to) {
              return moment(task.CreatedDate).isBetween(
                this.createdDateFilter.from,
                this.createdDateFilter.to,
                undefined,
                "[]"
              );
            } else if (this.createdDateFilter.from) {
              return moment(task.CreatedDate).isSameOrAfter(
                moment(this.createdDateFilter.from)
              );
            } else if (this.createdDateFilter.to) {
              return moment(task.CreatedDate).isSameOrBefore(
                moment(this.createdDateFilter.to)
              );
            }
            return true;
          }
        );
      }
    },
    //kérdőív kijelölt sorok tárolása
    questionnaireSelected(row) {
      this.selectedQuestionnaire = row;
    },
    //beteglista szűrése
    async filterParticipantList(input) {
      if (input) {
        if (input.length > 2) {
          this.participantList = await this.getParticipantList({
            MultiValue: input,
          });
        } else {
          this.participantList = [];
        }
      } else {
        if (
          this.$loggedUser.SelectedProject &&
          this.$loggedUser.SelectedProject.ProjectId
        ) {
          this.participantList = await this.getParticipantList({
            "-orderby": "Name",
            "-limit": 10,
          });
        }
      }
    },
    //kérdőívek kiosztása a betegeknek kérés
    async renderQuestionnaireToParticipant() {
      //body felépítése
      const temp = {
        FormSchemaId: this.selectedQuestionnaire[0].FormSchemaId,
        FormElements: [],
        TaskNeeded: this.asTask,
        startTime: this.asTask ? this.startDate : null,
        Deadline: this.asTask ? this.deadlineDate : null,
        HardDeadline: this.asTask ? this.hardDeadline : false,
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      };
      //a beteglistában megjelölt betegek idjainak kiszedése a bodyba
      for (const p of this.selectedParticipantList) {
        /* //a beteg Active jelölője true?
        if (p.isActive) { */
        //igen, body beteglistába beillesztjük a kiválasztott beteg idját
        temp.FormElements.push({
          SubjectId: p.UserId,
          AssigneeId: p.UserId,
          EvaluatorId: this.$loggedUser.UserId,
        });
        /*  } */
      }
      //kiosztás kérés indítása
      const renderQuestionnaireToParticipantResponse = await FormLogic.createFormInstance(
        temp
      );
      //sikeres kérés?
      if (
        !renderQuestionnaireToParticipantResponse.Code ||
        renderQuestionnaireToParticipantResponse.Code == 0
      ) {
        //sikeres üzenet
        this.$bvToast.toast(
          this.$t("requestResponse.questionnaire.successAssignedMessage"),
          {
            title: this.$t(
              "requestResponse.questionnaire.successAssignedTitle"
            ),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(renderQuestionnaireToParticipantResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorAssignedMessage"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },

    async getParticipantList(params) {
      const baseRoles = this.$productSettings[this.managerType]
        .participantsBaseRoles;
      const onlySubordinates =
        this.$productSettings[this.managerType].participantListLoadType ==
        this.$enums.SystemParameters.ParticipantListLoadType.JustRelationship
          .Value;
      const tempList = [];
      await Promise.all(
        baseRoles.map(async (role) => {
          const getResponse = await ProjectLogic.getParticipant(
            this.$loggedUser.SelectedProject.ProjectId,
            {
              BaseRole: role,
              OnlySubordinates: onlySubordinates,
              ...params,
            }
          );
          if (!getResponse.Code) {
            tempList.push(getResponse);
          } else {
            this.$bvToast.toast(getResponse.Message, {
              title:
                "Hiba történt a lista betöltése során! Előfordulhat, hogy a résztvevő lista egy részét nem sikerült betölteni.",
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        })
      );
      return tempList.flat();
    },
    //beteglista csoportosítása cimkék alapján
    /* generateGroupedParticipantList() {
      //betegek lista csoportodítása, megadott metódus alapján, arrayKey, cimke nélküli elemek csoport neve
      const participantMapGroupedByTags = HelperMethods.groupByArrayItems(
        this.filteredParticipantList,
        (x) => {
          return x.Tags.map((tag) => {
            return { Name: this.$getLanguagedText(tag.Name) };
          });
        },
        "Name",
        this.$t("healthProfessional.questionnaires.assignableTab.noTag")
      );
      //a csoportosított beteg lista tároló urítése
      this.patienListGroupedByTags = [];
      //kapott csoportosított map tárolása arrayként a kiürített tárolóba
      participantMapGroupedByTags.forEach((value, key) =>
        this.patienListGroupedByTags.push({ key: key, value: value })
      );
    }, */
    //kérdőív lista kérés
    async getQuestionnaireList() {
      if (this.projectItems) {
        const questionnaireListResult = await FormLogic.getFormSchemaHeader({
          FormCategory: "Questionnaire",
          Status: "Active",
        });
        //sikeres kérés?
        if (!questionnaireListResult.Code) {
          //kérdőív lista frissítés
          const schema = questionnaireListResult;
          this.questionnaireList = this.filteredQuestionnaireList = schema.filter(
            (s) =>
              this.projectItems.some(
                (item) =>
                  item.EntityTypeName ==
                    this.$enums.ProjectItemsEntityTypeName.FormSchema &&
                  s.FormSchemaId == item.EntityId
              )
          );
        } else {
          this.$bvToast.toast(questionnaireListResult.Message, {
            title: this.$t("requestResponse.basic.errorGetNamedList", {
              name: this.$t(
                "healthProfessional.questionnaires.assignableTab.errorName"
              ),
            }),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async setDefaultLists() {
      if (
        this.$loggedUser.SelectedProject &&
        this.$loggedUser.SelectedProject.ProjectId
      ) {
        this.participantList = await this.getParticipantList({
          "-orderby": "Name",
          "-limit": 10,
        });
      }
      await this.getQuestionnaireList();
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    await this.setDefaultLists();
  },
};
</script>
<style scoped>
.questionnaires-to-patient-table {
  background: white;
}
.questionnaires-to-patient-table-box {
  padding: 10px;
}
.questionnaires-to-patient-table-title {
  font-size: 28px;
  text-align: center;
}
.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: -100px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
</style>
